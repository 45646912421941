@import "../../scss/vars.scss";

.content {
  padding-top: 40px;
  padding-bottom: 150px;

  @media screen and (max-width: 767px) {
    padding-bottom: 80px;
    padding-top: 100px;
  }

  @media screen and (max-width: 1650px) and (min-width: 768px) {
    padding-top: 150px;
  }
}
