@import "assets/App/scss/vars.scss";

.TopIcon {
  position: relative;
  background-color: $colorWhite;
  border-radius: 15px;
  font-size: 0.9rem;
  line-height: 1.5rem;
  box-shadow: rgba(0, 0, 0, 0.3) 3px 3px 3px 1px;
  margin-left: 15px;

  .TopIconIcon {
    transition: all 0.2s ease;
    display: inline-block;
    background-color: $colorGrey;
    color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 1.82rem;
    //padding: 0;
    text-align: center;
  }

  &:hover {
    cursor: pointer;
    background-color: $colorGrayLightBg;
    color: $colorBrand;
    .TopIconIcon {
      background-color: $colorBrand;
      //color: $colorBrand;
    }
  }

  @media screen and (max-width: 767px) {
    min-width: 147px;
    margin-bottom: 8px;
  }

}

.TopIconText {
  padding-left: 15px;
  padding-right: 15px;
  @media screen and (max-width: 767px) {
    padding-left: 7px;
    padding-right: 0;
  }
}

.TextBottom {
  position: absolute;
  right: 10px;
  bottom: -10px;
  font-size: 0.6rem;
  background-color: $colorBrand;
  padding: 0 3px;
  border-radius: 4px;
}
