@import "assets/App/scss/vars.scss";

.Table {
  border-collapse: separate;
  border-spacing: 0 10px;
  thead {
    tr {
      th {
        padding-bottom: 0;
      }
    }
  }
  tbody {
    tr {
      background-color: #e1e3e7; // todo: inherit color?
      td {
        &:global(.bright) {
          background-color: #ecedf0; // todo: inherit color?
        }
        &:global(.textInput) {
          padding: 0;
          input[type="text"] {
            background-color: transparent;
          }
        }
      }
    }
  }
}