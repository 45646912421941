@import "assets/App/scss/vars.scss";

.header {
  color: $colorBrand;
  font-size: 0.875rem;
  position: relative;
}

.logo {
  min-width: 196px;

  @media (min-width: 992px) {
    width: auto;
  }
  @media (min-width: 1200px) {
    width: 260px;
  }
}