@import "assets/App/scss/vars.scss";

.basket {
  display: block;
  text-decoration: none;
  font-size: 1.25rem;
  span {
    font-size: 0.875rem;
  }
  color: $colorBlue;
  &:hover {
    color: $colorBlue;
  }
}

.basketPrice {
  font-weight: $fontWeightHeader;
  display: flex;
  align-items: center;
}