@import "assets/App/scss/vars.scss";

$diameter: 80px;

.NavLink {
  padding-left: 5px;

  padding-right: 5px;
  text-decoration: inherit;
  color: inherit;

  @media screen and (min-width: 768px) {
    &:hover {
      .NavIcon {
        margin-top: 0;
        width: $diameter + 10px;
        height: $diameter + 10px;
        padding-top: 5px;
      }
    }
  }

  &.active {
    .NavIcon {
      background-color: $colorBlueBright;
    }
    color: inherit;
  }
  .NavIcon {
    margin-top: 15px;
    transition: all 0.2s ease;
    background-color: $colorWhite;
    border-radius: 50%;
    width: $diameter;
    height: $diameter;
    box-shadow: 3px 3px 3px 1px rgba(0, 0, 0, 0.3);
    text-align: center;

    @media screen and (max-width: 767px) {
      margin-top: 0;
      $diameter: 60px;
      width: $diameter;
      height: $diameter;
    }

    .Icon {
      padding-top: 14px;
      font-size: 30px;
      @media screen and (max-width: 767px) {
        padding-top: 8px;
        font-size: 25px;
      }
    }
    .Text {
      margin-top: -8px;
      font-size: 10px;
      color: $colorGrayDarkText;
      @media screen and (max-width: 767px) {
        font-size: 8px;
      }
    }
  }
  &:hover {
    cursor: pointer;
    color: inherit;
  }
}
