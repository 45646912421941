@import "assets/App/scss/vars.scss";

.Accordion {
  :global(.accordion-item) {
    // Item style
    border-color: $colorWhite;
    :global(.accordion-header) {
      button {
        // Header style
        padding-left: 0;
        padding-top: .5rem;
        padding-bottom: .5rem;
        box-shadow: none;
        &[aria-expanded="true"] {
          // Header style when expanded
          background-color: $colorWhite;
          font-weight: bold;
          color:$colorGrayDarkText;
        }
        &::after {
          // Right Icon style, modify color with filter if needed
          // filter: brightness(1000%);
        }
      }
    }
    :global(.accordion-body) {
      // Body style
    }
  }
}