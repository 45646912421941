@import '../../../../scss/vars.scss';
@import '../../../../scss/Bootstrap.scss';

.DiscountPromotion {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $danger;
  color: $colorWhite;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 0.3rem 0.6rem;
  font-size: 1.5rem;
  z-index: 9;
  text-transform: uppercase;
  border-radius: 5px;
  font-style: italic;
  font-weight: bold;

  @media screen and (min-width: $mediaBreakpointXL) {
    padding: 10px;
    font-size: 2rem;
    width: 250px;
    height: 50px;
  }
}
