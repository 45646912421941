.stickyBar {
    position: sticky;
    bottom: 0;
    width: calc(100% + 48px * 2);
    transform: translateX(-48px);
    background-color: #e9e9e9;
    padding: 12px 36px;
    @media screen and (max-width: 992px) {
      width: calc(100% + 24px * 2);
      transform: translateX(-24px);
      padding: 12px;
    }
  }
  
  