@import '/assets/App/scss/vars.scss';
@import '/assets/css/shop/vars.scss';

.NcwHomeBGImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75%;
  z-index: -1;
  background-size: cover;
  background-position: center;
}

.Overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -2;
  background-color: $colorNcw;
}
