@import "assets/App/scss/vars.scss";

.Footer {
  background-color: $colorGreyBright;
  color: $colorGrayDarkText;
  font-size: 0.875rem;
}

.disclaimer {
  font-size: 0.8rem;
}