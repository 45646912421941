@import "assets/App/scss/vars.scss";

.dropdown {
  min-width: 160px;
  width: 380px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  z-index: 10;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-color: $colorGrayLightBg;
  right: -30px;
  top:13px;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 22px;
    height: 11px;
    border-color: transparent;
    border-style: solid;
    top: -22px;
    right: 10px;
    border-bottom-color: $colorGrayLightBg;
    border-width: 11px;
    pointer-events: none;
  }
}
