@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=1521b41f-b462-456a-94fe-8b77dcaf00bd&fontids=5664093,5664098,5664103,5664107,5664111,5664115,5664119,5664150");

// Helvetica Neue LT
@font-face{
  font-family:"Helvetica Neue LT";
  src:url("../../font/ShopFonts/5664093/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix");
  src:url("../../font/ShopFonts/5664093/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix") format("eot"),url("../../font/ShopFonts/5664093/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2") format("woff2"),url("../../font/ShopFonts/5664093/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff") format("woff"),url("../../font/ShopFonts/5664093/8f4a1705-214a-4dd0-80b8-72252c37e688.ttf") format("truetype");
  font-weight: 400;
}
@font-face{
  font-family:"Helvetica Neue LT";
  src:url("../../font/ShopFonts/5664098/c708845d-fe72-4432-9276-47c4f91aec1c.eot?#iefix");
  src:url("../../font/ShopFonts/5664098/c708845d-fe72-4432-9276-47c4f91aec1c.eot?#iefix") format("eot"),url("../../font/ShopFonts/5664098/4bd56f95-e7ab-4a32-91fd-b8704cbd38bc.woff2") format("woff2"),url("../../font/ShopFonts/5664098/4fe1c328-1f21-434a-8f0d-5e0cf6c70dfb.woff") format("woff"),url("../../font/ShopFonts/5664098/c07fd9e2-5e0b-4297-9229-af0c8e331d94.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face{
  font-family:"Helvetica Neue LT";
  src:url("../../font/ShopFonts/5664103/5640036d-d549-4f16-b31e-87da78d9e832.eot?#iefix");
  src:url("../../font/ShopFonts/5664103/5640036d-d549-4f16-b31e-87da78d9e832.eot?#iefix") format("eot"),url("../../font/ShopFonts/5664103/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2") format("woff2"),url("../../font/ShopFonts/5664103/7802e576-2ffa-4f22-a409-534355fbea79.woff") format("woff"),url("../../font/ShopFonts/5664103/14d824a2-2a5c-4692-8e30-74f6e177b675.ttf") format("truetype");
  font-weight:500;
}
@font-face{
  font-family:"Helvetica Neue LT";
  src:url("../../font/ShopFonts/5664107/46116ca7-5e49-4a08-9af4-07e7182ce7fd.eot?#iefix");
  src:url("../../font/ShopFonts/5664107/46116ca7-5e49-4a08-9af4-07e7182ce7fd.eot?#iefix") format("eot"),url("../../font/ShopFonts/5664107/de68be2a-5d0e-4b8d-b3eb-940f75503e2a.woff2") format("woff2"),url("../../font/ShopFonts/5664107/31029e78-79a0-4940-b82d-2e3c238e1355.woff") format("woff"),url("../../font/ShopFonts/5664107/a8860880-ef55-430f-b420-eccd71f67b19.ttf") format("truetype");
  font-weight:500;
  font-style:italic;
}
@font-face{
  font-family:"Helvetica Neue LT";
  src:url("../../font/ShopFonts/5664111/ae7ba1a5-8e73-4170-81ae-a7d26629e728.eot?#iefix");
  src:url("../../font/ShopFonts/5664111/ae7ba1a5-8e73-4170-81ae-a7d26629e728.eot?#iefix") format("eot"),url("../../font/ShopFonts/5664111/13ab58b4-b5ba-4c95-afde-ab2608fbbbd9.woff2") format("woff2"),url("../../font/ShopFonts/5664111/5018b5b5-c821-4653-bc74-d0b11d735f1a.woff") format("woff"),url("../../font/ShopFonts/5664111/c20f44a1-784c-4d3f-8d7f-aee889b6a7ae.ttf") format("truetype");
  font-weight:700;
  font-style:italic;

}
@font-face{
  font-family:"Helvetica Neue LT";
  src:url("../../font/ShopFonts/5664115/80e123ee-1929-41ec-bf6a-77f92f0d53fe.eot?#iefix");
  src:url("../../font/ShopFonts/5664115/80e123ee-1929-41ec-bf6a-77f92f0d53fe.eot?#iefix") format("eot"),url("../../font/ShopFonts/5664115/7e42a406-9133-48c0-a705-4264ac520b43.woff2") format("woff2"),url("../../font/ShopFonts/5664115/837750e9-3227-455d-a04e-dc76764aefcf.woff") format("woff"),url("../../font/ShopFonts/5664115/cbb49e47-093c-4029-b64c-b5ee08064205.ttf") format("truetype");
  font-weight:900;
}
@font-face{
  font-family:"Helvetica Neue LT";
  src:url("../../font/ShopFonts/5664119/1be05894-26dd-4ade-91b4-b50e43d62c83.eot?#iefix");
  src:url("../../font/ShopFonts/5664119/1be05894-26dd-4ade-91b4-b50e43d62c83.eot?#iefix") format("eot"),url("../../font/ShopFonts/5664119/0acba88f-0de4-4d43-81fd-920d7427f665.woff2") format("woff2"),url("../../font/ShopFonts/5664119/713c9c40-9cbd-4276-819e-d0efaf5d3923.woff") format("woff"),url("../../font/ShopFonts/5664119/b747296b-72be-4443-99e0-8f388100ced0.ttf") format("truetype");
  font-weight:900;
  font-style:italic;
}
@font-face{
  font-family:"Helvetica Neue LT";
  src:url("../../font/ShopFonts/5664150/4c21ab66-1566-4a85-b310-fbc649985f88.eot?#iefix");
  src:url("../../font/ShopFonts/5664150/4c21ab66-1566-4a85-b310-fbc649985f88.eot?#iefix") format("eot"),url("../../font/ShopFonts/5664150/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2") format("woff2"),url("../../font/ShopFonts/5664150/7b415a05-784a-4a4c-8c94-67e9288312f5.woff") format("woff"),url("../../font/ShopFonts/5664150/f07c25ed-2d61-4e44-99ab-a0bc3ec67662.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "newcomer-week";
  src: url("../../font/ShopFonts/newcomer-week/GothamCond-Ultra.otf");
}