@import "assets/App/scss/vars.scss";

.searchResult {
  position: absolute;
  left:50%;
  width: 80%;
  z-index: 20;
  transform: translateX(-50%);
  max-width: 1296px;

  .searchResultBrands, .searchResultProducts {
    border: 1px solid $colorDisabled;
    border-collapse: collapse;
    background-color: $colorWhite;
    color: $colorGrayDarkText;
  }
  .searchResultProducts {
    border-top: none;
  }

  h2 {
    font-size: 1rem !important;
    margin: 0;
    padding: 1rem 15px 0.25rem 15px;
  }

  ul {
    list-style: none;
  }

  & a {
    text-decoration: none;
    color: black;
    width: 100%;

    &:hover {
      color: black;
    }
  }
}

.imageContainer {
  width: 20%;

  @media (max-width: 991px) {
    display: none;
  }
}

.textContainer {
  width: 80%;

  @media (max-width: 991px) {
    display: flex;
  }
}
