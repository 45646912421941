@import "assets/App/scss/vars.scss";

.iconLink {
  text-decoration: none;
  cursor: pointer;
  color: $colorBlue;
  //&:hover {
  //  color: $colorBlue;
  //}
}

.iconLinkText {
  font-size: 0.75rem;
  font-weight: bolder;
  font-style: italic;
}

.iconLinkIcon {
  font-size: 1.25rem;
}