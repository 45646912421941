@import "assets/App/scss/vars.scss";

.Countdown {
  div {
    display: inline-block;
    font-size: 4rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-weight: bold;
    span {
      font-size: 2rem;
      padding-left: .3rem;
      font-weight: normal;
    }
  }
  @media (max-width: $mediaBreakpointLG) {
    div {
      font-size: 3rem;
      padding-left: 0.6rem;
      padding-right: 0.6rem;
      span {
        font-size: 1rem;
      }
    }
  }
}