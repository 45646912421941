.new {
  position: absolute;
  height: 66px;
  width: 66px;
  background: #cf0815;
  border-radius: 100%;
  line-height: 66px;
  text-align: center;
  color: white;
  font-weight: bold;
  font-style: italic;
  font-size: 1.143rem;
  top: 0;
}

.overlayImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  backface-visibility: hidden;
}
