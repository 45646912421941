@import '/assets/css/shop/vars.scss';

.NewcomerWeekHtml::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0;
  height: 0;
}

.VideoContainer {
  position: relative;
  width: 100%;
  height: 100%;

  .BackgroundVideo {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
}
