@import '/assets/App/scss/vars.scss';

/*.QuantityInput {
  text-align: center;
  height: 100%;
  width: 100px !important;
  border: 2px solid #333 !important;
  border-radius: 10px !important;

  &:focus {
    border-color: $colorBrand !important;
    outline: none;
  }
}

@media (min-width: $mediaBreakpointXL) {
  .PromoPriceContainer {
    order: 2;
  }
  .FlavorContainer {
    order: 1;
  }
}*/

.SizeRow {
  .SizeNameAboveImage {
    @media (min-width: 2000px) {
      display: none;
    }
  }
  .SizeNameAbovePromo {
    padding-bottom: 10px;
    display: none;
    @media (min-width: 2000px) {
      display: block;
    }
  }
  .SizePromo {
    background-color: $colorRed;
    color: $colorWhite;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 4px;
    font-style: italic;
  }
  .SizePriceReference {
    padding-top: 20px;
    text-align: right;
    color: #aaaaaa;
    text-decoration: line-through;
    font-size: 1.2rem;
    font-weight: bold;
    font-style: italic;
    span {
      font-size: 1rem;
    }
  }
  .SizePriceDiscounted {
    text-align: right;
    font-size: 2.5rem;
    color: #ff0000;
    font-weight: 900;
    font-style: italic;
    span {
      font-size: 1rem;
      color: #ffffff;
    }

    @media (max-width: $mediaBreakpointMD) {
      font-size: 2rem;
    }
  }

  .SizeFlavoursTable {
    width: 100%;
    .SizeFlavoursTableRow {
      .SizeFlavoursTableQty {
        border-radius: 8px;
        border: none;
        text-align: center;

        &::placeholder {
          color: $colorWhite;
        }
      }
    }
    .SizeFlavoursDividerRow {
      &:last-child {
        display: none;
      }
    }
  }
}

.SizeDividerRow {
  &:last-child {
    display: none;
  }
}

.ModalDialog {
  justify-content: center;
}

.ModalContent {
  border: 0 !important;
  max-width: 600px;

  .ModalHeader {
    border: 0 !important;
    padding-block: 0;
    padding-top: 15px;
  }

  .ModalBody {
    padding: 0;
  }
}

.SwiperWrapper {
  display: grid;
  align-items: stretch;

  :global(.swiper-pagination) {
    width: 50% !important;
    line-height: 10px;
    position: initial !important;
  }

  // nutration facts img
  :global(.swiper-slide) {
    display: flex;
    align-items: center;

    img.ImageNF {
      min-height: 160px;
      max-height: 260px;
      object-fit: none;
      object-position: top left;
      padding: 0px 10px 10px 0px;

      @media (min-width: $mediaBreakpointLG) {
        min-height: 260px;
      }
    }
  }
}
