.CloseBtn {
  height: 65px;
  border-radius: 50%;
  width: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 1rem;
  top: 1rem;

  cursor: pointer;
  // transition: scale 300ms;

  &:hover {
    // scale: 1.1;
    transition-duration: 0.1s;
    // background-color: #3a3a3a;
  }

  &::after {
    content: '';
    position: absolute;
    border-radius: 4em;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
    box-shadow: 0 0 10px 40px white;
  }

  &:active::after {
    box-shadow: 0 0 0 0 white;
    position: absolute;
    border-radius: 4em;
    left: 0;
    top: 0;
    opacity: 1;
    transition: 0s;
  }

  &:active {
    top: 11px;
  }
}
