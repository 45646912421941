@import "assets/App/scss/vars.scss";

.navigation {
  display: flex;
  flex-direction: column;

  padding: 16px 32px;
  height: auto;

  color: $colorBlack;
  background-color: $colorGrayLightBg;
  font-size: 0.75rem;

  border-bottom: 1px solid rgb(170, 170, 170);
}
