@import "../../../../../scss/vars.scss";

.RowLinked {
  @media (min-width: 1200px) {
    &:hover {
      opacity: .5;
    }
  }
}

.previewDiv {
  padding:10px;
  cursor: pointer;
  &:hover {
    background-color: $colorGrayLightBg;
  }
}

.mobileSmall {
  @media (max-width: $mediaBreakpointMD) {
    opacity: .5;
    font-size: 0.8rem;
    line-height: 1rem;
    font-style: italic;
  }
}