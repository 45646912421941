.FixedContainer {
  position: fixed;
}
.TopLeft {
  top: 40px;
  left: 40px;
  width: 250px;
  @media screen and (max-width: 767px) {
    top: 10px;
    left: 10px;
    width: 200px;
  }
}
.TopRight {
  top: 40px;
  right: 40px;
  display: flex;
  @media screen and (max-width: 767px) {
    top: 10px;
    right: 10px;
    flex-direction: column;
  }
}
.BottomCenter {
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  @media screen and (max-width: 767px) {
    bottom: 10px;
  }
}
.TopCenter {
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  // @media screen and (max-width: 767px) {}
}
