@import '../../../scss/vars.scss';

.PaginatorContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  z-index: 10;
  height: 56px;

  .PaginationInfo {
    background: $colorWhite;
    color: $colorNcw;
    padding: 9px;
    font-weight: 600;
    border-radius: 20px;
    gap: 10px;
    display: flex;
  }
}
