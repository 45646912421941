.inheritColor {
  color: inherit;
}

@media (hover: hover) {
  .opacityOnHover:hover {
    color: inherit;
    opacity: 0.6;
  }
}

.underline {
  text-decoration: underline;
}

.noUnderline {
  text-decoration: inherit;
}
