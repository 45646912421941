$vtsHeaderHeight: 0px;

.exhibition {
  left: 40%;
  width: 20%;
  height: calc(100vw / 16 * 9 * (21 / 100));
  top: calc((100vw / 16 * 9 * (49 / 100)) - (100vw / 16 * 9 - (100vh - #{$vtsHeaderHeight})) / 2);
  //background-color: rgba(255, 0, 0, 0.3);
  position: absolute;
  @media (max-aspect-ratio: 16/9) {
    left: calc(((100vh - #{$vtsHeaderHeight}) / 9 * 16 * (40 / 100)) - ((100vh - #{$vtsHeaderHeight}) / 9 * 16 - 100vw) / 2);
    top: 48%;
    height: 22%;
    width: calc((100vh - #{$vtsHeaderHeight}) / 9 * 16 * (20 / 100));
  }
}

.information {
  left: 80%;
  width: 14%;
  height: calc(100vw / 16 * 9 * (20 / 100));
  top: calc((100vw / 16 * 9 * (60 / 100)) - (100vw / 16 * 9 - (100vh - #{$vtsHeaderHeight})) / 2);
  //background-color: rgba(255, 0, 255, 0.3);
  position: absolute;
  @media (max-aspect-ratio: 16/9) {
    left: calc(((100vh - #{$vtsHeaderHeight}) / 9 * 16 * (79 / 100)) - ((100vh - #{$vtsHeaderHeight}) / 9 * 16 - 100vw) / 2);
    top: 60%;
    height: 20%;
    width: calc((100vh - #{$vtsHeaderHeight}) / 9 * 16 * (14 / 100));
  }
}