@import "../../../scss/vars.scss";
.sortBySelect {
  @media (max-width: calc($mediaBreakpointLG - 1px)) {
    height: 48px;
    width: 100%;
    border-radius: 0px;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    cursor: pointer;
  }
}
