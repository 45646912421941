@import 'assets/App/scss/vars.scss';

.Container {
  position: relative;
  opacity: 0;
  transition: opacity 700ms ease-in-out;
  min-width: 100vw;
  // scroll-snap-type: y mandatory;
  // -webkit-scroll-snap-type: y mandatory;
  // scroll-padding-top: 56px;
}

.Container.ActiveProduct {
  opacity: 1;
}
