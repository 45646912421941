@import "assets/App/scss/vars.scss";

.accordion {
  :global(.accordion-item) {
    // Item style
    &:first-child {
      border-top:0;
    }
    border-left:0;
    border-right:0;
    :global(.accordion-header) {
      position: relative;
      button {
        // Header style
        padding-left: 0;
        padding-top: 1rem;
        padding-bottom: 1rem;
        box-shadow: none;

        font-weight: bold;
        font-style: italic;
        font-size: 1.2rem;

        &[aria-expanded="true"] {
          // Header style when expanded
          background-color: transparent;
          color: inherit;
        }
        &::after {
          // Right Icon style, modify color with filter if needed
          // filter: brightness(1000%);
        }
      }
    }
    :global(.accordion-body) {
      // Body style
      padding: 0 0 2.5rem 0;
      line-height: 2.5rem;
      @media (max-width: $mediaBreakpointLG) {
        line-height: 1.6rem;
      }
    }
  }
}

.rowInHeader {
  position: absolute;
  height: 100%;
  width: calc(100% + var(--bs-gutter-x));
  line-height: 3.2rem;
}