@import "assets/App/scss/vars.scss";

.menuItem {
  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;
  color: $colorBlack;

  &:hover {
    color: inherit;
  }

  border: 1px solid rgb(170, 170, 170);
  border-top: none;
  border-left: none;
  border-right: none;

  height: 48px;
  padding: 0 1rem;

  cursor: pointer;
}

.menuItemGray {
  background-color: $colorWhite;
}
