@import 'assets/App/scss/vars.scss';

.PageProductContainer {
  display: flex;
  height: inherit;
  min-height: 100%;
  height: 100%;
  min-width: 100%;
  // max-height: 100%;
  // overflow: auto;
  position: absolute;
  transition: all 1s;
}
