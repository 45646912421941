@import '../../../../scss/vars.scss';

.columnContainer {
  column-count:4;
  @media (max-width: $mediaBreakpointXL) {
    column-count:3;
  }
  @media (max-width: $mediaBreakpointMD) {
    column-count:2;
  }
}

.item {
  padding-top: 1rem;
  max-width: 100%;
  @media (max-width: $mediaBreakpointXL) {
    padding-top:.5rem;
  }
  @media (max-width: $mediaBreakpointMD) {
    padding-top:.1rem;
  }
  .itemLink {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    display:inline-block;
  }
}