@import '/assets/css/shop/vars.scss';

.CustomCursor {
  position: fixed;
  pointer-events: none;
  z-index: 9999;
  color: #fff;
  border-radius: 50%;
  border: 3px solid #fff;
  height: 75px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.CustomCursor.IsHovering {
  display: flex;
}
.CustomCursor.NotHovering {
  display: none;
}
