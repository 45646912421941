@font-face{
  font-family:"Helvetica Neue LT";
  src:url("Fonts/5663746/02980b3d-1e37-4378-a5c6-4cf0d6ce0ba5.woff2") format("woff2"),url("Fonts/5663746/2d1e8a5f-eb7a-47a1-be1d-878bb9a46b6e.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: condensed;
}
@font-face{
  font-family:"Helvetica Neue LT";
  src:url("Fonts/5663751/83988233-0e17-4c53-b35a-2f1c0c2a3ca1.woff2") format("woff2"),url("Fonts/5663751/22f5df0d-ec99-4f2e-a776-ca2bdf0df48a.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: condensed;
}