@import "../../../../scss/vars.scss";

.TabButton {
  background-color: $colorGreyBright;
  color: $colorGrey;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
  &.active {
    background-color: $colorGrey;
    color: $colorGreyBright;
  }
  @media (max-width: 1080px) {
    display: block;
  }
}
.Icon {
  font-size: 25px;
}
.Text {
  @media (max-width: 993px) {
    font-size: 0.7rem;
  }
  text-align: center;
}
