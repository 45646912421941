@import "assets/App/scss/vars.scss";

.sliderNav {
  position: absolute;
  top:50%;
  transform: translateY(-50%);

  &:global(.swiper-button-disabled) {
    color: $colorDisabled;
  }
}

.sliderPrev {
  @extend .sliderNav;
  left: 0;
}

.sliderNext {
  @extend .sliderNav;
  right: 0;
}

.letter {
  font-weight: bold;
  color: $colorDisabled;
  &.letterActive {
    color: $colorBrand;
  }
}