@import "assets/App/scss/vars.scss";

.gymAssortmentBadge {
  font-weight: bold;
  font-style: italic;
  text-decoration: none;
  text-align: center;
  //color: black !important;
  background-color: $colorGold;
  height: auto !important;
  border-radius: 5px;
  width: auto;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
