@import "assets/App/scss/vars.scss";

.unitCount {
  position: absolute;
  top: -0.25rem;
  right: 0.75rem;
  line-height: 1;
  text-align: center;
  font-size: 0.625rem !important;
  font-style: normal;
  margin-left: 0;
  text-indent: -1px;
  pointer-events: none;
  background-color: $colorGold;
  background-image: linear-gradient(#fbda2f, $colorGold);
  color: $colorBrand;
  border-radius: 2px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.7);
  padding: 1px 2px 1px 2px;
}
