@import "assets/App/scss/vars.scss";

.Ncw {
  :global(h1) {
    font-style: italic;
    font-weight: 900;
  }
  :global(h2) {
    font-style: italic;
    font-size: 2rem;
    font-weight: 900;
  }
  :global(h3) {
    font-style: italic;
    font-size: 1.6rem;
    font-weight: 900;
  }
  :global(h4) {
    font-style: italic;
    font-size: 1.4rem;
    font-weight: 900;
  }
}