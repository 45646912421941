@import "assets/App/scss/vars.scss";

.Popup {

}

.PopupGridWidth {
  @media (min-width: $mediaBreakpointSM) {
    min-width: $mediaBreakpointSM;
  }
  @media (min-width: $mediaBreakpointMD) {
    min-width: $mediaBreakpointMD;
  }
  @media (min-width: $mediaBreakpointLG) {
    min-width: $mediaBreakpointLG;
  }
  @media (min-width: $mediaBreakpointXL) {
    min-width: $mediaBreakpointXL;
  }
  @media (min-width: $mediaBreakpointXXL) {
    min-width: $mediaBreakpointXXL;
  }
}