@import '/assets/App/scss/vars.scss';

.ProductListContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .UlProductList {
    list-style: none;
    overflow: auto;
  }
  @media screen and (min-width: $mediaBreakpointXL) {
    .UlProductList {
      height: 52%;
      max-height: 350px;
    }
  }

  .SizeDividerRow {
    &:last-child {
      display: none;
    }
  }
}

@media (min-width: $mediaBreakpointXL) {
  .ProductListContainer {
    gap: 1.9rem;
  }
}
