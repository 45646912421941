$mediaBreakpointXXL: 1400px;
$mediaBreakpointXL: 1200px;
$mediaBreakpointLG: 992px;
$mediaBreakpointMD: 768px;
$mediaBreakpointSM: 576px;


/* Colors */
$colorBlack: #000000;
$colorWhite: #ffffff;
$colorBlue: #254a91;
$colorNcw: #233c6a;
$colorBlueBright: #5b93ff;
$colorGrayLightBg: #e9e9e9;
$colorGrayDarkText: #1a1a1a;
$colorDisabled: #999;
$colorGrey: #4a4a4a;
$colorGreyBright: #f2f2f2;
$colorRed: rgb(207, 8, 21);
$colorLightRedProductList: #ee6b6b;
$colorLightRed: #ffcccc;

/* Color abstraction */
$colorBrand: $colorBlue;

$success: #5bbc1b;
$danger: #cf0815;

$colorGold: #ffd200;


$fontWeightHeader: 500;