//@import "../../../font/LT-WebFonts-515537802/demo.css";
@import 'assets/App/scss/vars.scss';

/*a.inherit {
  color: inherit;
  text-decoration: inherit;
  &:hover {
    color: $colorBlue;
  }
}*/

//.VirtualTradeShow {
//  .grecaptcha-badge {
//    visibility: hidden;
//  }
//}

//.ReactModal__Overlay {
//  opacity: 0;
//  transition: opacity 200ms ease-in-out;
//}
//
//.ReactModal__Overlay--after-open {
//  opacity: 1;
//}
//
//.ReactModal__Overlay--before-close {
//  opacity: 0;
//}
//
//// Zendesk Widget Manipulation
//#launcher {
//  z-index: auto !important;
//  top: 70px !important;
//  right: 7px !important;
//  transform: scale(0.8, 0.8);
//  -ms-transform: scale(0.8, 0.8); /* IE 9 */
//  -webkit-transform: scale(0.8, 0.8); /* Safari and Chrome */
//  -o-transform: scale(0.8, 0.8); /* Opera */
//  -moz-transform: scale(0.8, 0.8); /* Firefox */
//
//  @media (max-width: $mobileMaxWidth) {
//    top: 146px !important;
//    right: -19px !important;
//  }
//}

.GuidedTourContainer {
  left: 50%;
  bottom: 54dvh;
  z-index: 2;
}

@media screen and (min-width: $mediaBreakpointLG) {
  .GuidedTourContainer {
    left: 60%;
    bottom: 50dvh;
  }
}
