@import '../../../../scss/vars.scss';

.GuidedTourContainer {
  background-color: $colorWhite;
  border-radius: 20px;
  padding: 10px;
  border: 2px solid $colorBlack;
  min-width: 90vw;
  max-width: 90vw;
  height: auto;
  position: relative;

  button {
    margin-left: 7px;
  }

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 100%;
    left: 75%;
    transform: translateX(-50%);
    border-left: 24px solid transparent;
    border-right: 24px solid $colorBlack;
    border-top: 24px solid $colorBlack;
    border-bottom: 24px solid transparent;
  }
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 100%;
    left: 75%;
    transform: translateX(-50%);
    border-left: 22px solid transparent;
    border-right: 21px solid $colorWhite;
    border-top: 21px solid $colorWhite;
    border-bottom: 21px solid transparent;
  }

  .SpeechBubbleText {
    display: inline-block;
    margin-left: 10px;
    margin-top: 5px;
    cursor: pointer;
  }
}

@media screen and (min-width: $mediaBreakpointSM) {
  .GuidedTourContainer {
    min-width: 500px;
    max-width: 500px;
  }
}

.ShowConsentCard {
  visibility: visible;
}
.HideConsentCard {
  visibility: hidden;
}
