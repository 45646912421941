@import "assets/App/scss/vars.scss";
@import "./scss/Fonts";
@import "../font/LT-WebFonts-515537802/ShopFonts";


/* Bootstrap */
@import "./scss/Bootstrap";

.naskor-shop {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  font-family: "Helvetica Neue LT", Helvetiva, sans-serif;
  color: $colorGrayDarkText;

  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    display: block;
    width: 100%;
  }

  .naskor-shop-footer {
    background-color: $colorGrayLightBg;
    font-size: 0.9rem;
    .disclaimer {
      font-size: 0.75rem;
    }
  }

  .naskor-shop-main {
    flex: 1 0 auto;
  }
}

.flex-col-grow {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

// .CustomScrollbar {
// direction: rtl;
// }

.CustomScrollbar > * {
  direction: ltr;
  text-align: start;
}

.CustomScrollbar::-webkit-scrollbar {
  width: 12px;
}

.CustomScrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.CustomScrollbar::-webkit-scrollbar-thumb {
  background-color: $colorBlack;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.CustomScrollbar {
  scrollbar-width: thin;
  scrollbar-color: $colorBlack #f1f1f1;
}