@import "assets/App/scss/vars.scss";

.NavigationButton {
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
  background-color: #fff;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

}

.NavigationIcons {
  color: $colorBrand;
}