@import "assets/App/scss/vars.scss";

.MenuItem {
  cursor: pointer;
}
.MenuItemText {
  font-weight: $fontWeightHeader;
  & > a {
    text-decoration: none;
  }
}