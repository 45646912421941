@import "../../../../../../scss/vars.scss";

.flipCard {
  background-color: transparent;
  width: 100%;
  max-height: 500px;
  aspect-ratio: 1/1;
  perspective: 1000px;
  overflow: hidden;

  font-weight: bold;

  .status,
  .visited {
    z-index: 9999;
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;

    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white;
    background-color: $colorGrey;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .visited {
    background-color: green;
  }

  &:hover {
    .flipCardInner {
      transform: rotateY(180deg);
    }
  }

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.imageBackground {
  margin-left: 50%;
  transform: translateX(-50%);

  max-height: 100%;
  object-fit: cover;
  object-position: center;
}

/* This container is needed to position the front and back side */
.flipCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Position the front and back side */
.flipCardFront,
.flipCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flipCardFront {
  background-color: transparent;
  color: black;
}

/* Style the back side */
.flipCardBack {
  background-color: transparent;
  transform: rotateY(180deg);

  .imageBackground {
    height: max-content;
    width: auto;
  }
}
