@import "assets/App/scss/vars.scss";

.favourites {
  text-decoration: none;
  text-align: center;
  font-size: 1.25rem;
  display: flex;
  flex-direction: column;
  color: $colorBlue;
  &:hover {
    color: $colorBlue;
  }
  span {
    font-size: 0.75rem;
  }
}