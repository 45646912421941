@import '/assets/App/scss/vars.scss';
@import '/assets/css/shop/vars.scss';

.NcwHomeBGImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 63%;
  z-index: -1;
  background-size: cover;
}

.Overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -2;
  background-color: $colorNcw;
}

.LPHeaderText {
  font-size: clamp(5rem, 5rem + 4dvw, 6rem);
  text-transform: uppercase;
  line-height: 0.8;
  font-style: normal !important;
  letter-spacing: 4px;
  position: relative;
}

.Images {
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  width: 100%;
}

.Images:hover {
  transform: scale(1.02);
  filter: brightness(1.1);
}

.DefaultFont {
  font-family: $fontDefault;
  text-wrap: balance;
}

.NewcomerWeekDate {
  letter-spacing: 0px;
  text-transform: uppercase;
  font-family: newcomer-week, Arial, sans-serif;
  position: absolute;
  z-index: 3;
  color: #000;
  transform: rotate(-10deg);
  padding: 5px;
  background-color: #19eb19;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  overflow: hidden;
  width: 200px;
  height: 40px;
  right: -100px;
  top: 70px;
  padding-top: 8px;
}

.NcwFaqs {
  font-size: 2rem;
  font-weight: bold;
  color: $colorBrand;
}

@media (min-width: 449px) and (max-width: $mediaBreakpointXL) {
  .UnderTHE {
    right: -80px;
    bottom: 0px;
    font-size: 1.3rem;
    width: 150px;
    height: 30px;
    top: auto;
  }
}
@media (min-width: $mediaBreakpointLG) {
  .LPHeaderText {
    font-size: clamp(5rem, 10px + 9dvw, 13rem);
  }
}

@media (min-width: $mediaBreakpointXL) {
  .UnderTHE {
    bottom: 0px;
    right: -100px;
    height: 40px;
    top: auto;
  }
}
@media (min-width: $mediaBreakpointXXL) {
  .UnderTHE {
    bottom: 10px;
  }
}
