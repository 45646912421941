@import "assets/App/scss/vars.scss";

.notifyUser {
  a {
    color: $colorBlack;
    text-decoration: none;

    &:hover {
      color: $colorBlue;
    }

    small {
      font-size: 0.75em;
    }
  }
}
