@import "assets/App/scss/vars.scss";

.SidebarWrapper {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: auto;

  width: 70vw;
  min-height: 100vh;

  align-items: stretch;

  margin-left: -70vw;
  transition: margin 0.2s;

  z-index: 10;

  background-color: $colorWhite;

  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

  &.visible {
    @media (max-width: $mediaBreakpointLG) {
      margin-left: 0px;
    }
  }
}
