@import "../scss/vars.scss";

.Wrapper {
  width: 100%;
  transition: margin 0.2s;
}

.sidebarOpen {
  @media (max-width: $mediaBreakpointLG) {
    margin-left: 70vw;
  }

  margin-left: 0;
}
