@use "sass:math";

$spacer: 1rem;
$spacers: (
        0: 0,
        1: math.div($spacer, 4),
        2: math.div($spacer, 2),
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 5,
        7: $spacer * 8,
        8: $spacer * 12,
);
$enable-negative-margins: true;

$primary: $colorBrand !default;
$secondary: #ecedf0;

$input-btn-font-size: 14px;

$btn-padding-y: 9px;
$btn-padding-x: 25px;

$input-padding-y: 9px;
$input-padding-x: 12px;
$input-bg: #ecedf0;
$input-disabled-color: #333333;
$input-disabled-bg: #eeeeee;
$input-disabled-border-color: #ecedf0;
$input-color: #333333;
$input-border-color: #ecedf0;
$input-border-width: 1px;
$input-box-shadow: none;
$input-border-radius: 0;
$input-focus-border-color: transparent;
$input-focus-box-shadow: none;
$input-height: 40px;

$form-select-focus-box-shadow: none;

$enable-rounded: false;

/* Require bootstrap */
@import "bootstrap/scss/bootstrap";

// Custom bootstrap utilities
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

$custom-colors: (
  "gold": $colorGold,
  "light-red": $colorLightRed,
  "light-gray": $colorGrayLightBg,
  "transparent": transparent,
  "disabled": $colorDisabled,
);

$theme-colors: map-merge($theme-colors, $custom-colors);

$utilities: map-merge(
  $utilities,
  (
    "background-color": (
      property: background-color,
      class: bg,
      local-vars: (
        "bg-opacity": 1,
      ),
      values:
        map-merge(
          $utilities-bg-colors,
          map-merge(
            $custom-colors,
            (
              "transparent": transparent,
            )
          )
        ),
    ),
    "color": (
      property: color,
      class: text,
      local-vars: (
        "text-opacity": 1,
      ),
      values:
        map-merge(
          $utilities-text-colors,
          map-merge(
            $custom-colors,
            (
              "muted": $text-muted,
              "black-50": rgba($black, 0.5),
              "white-50": rgba($white, 0.5),
              "reset": inherit,
            )
          )
        ),
    ),
    "cursor": (
      property: cursor,
      class: cursor,
      responsive: true,
      values: auto pointer grab,
    ),
  )
);

@import "bootstrap/scss/utilities/api";
