@import "assets/App/scss/vars.scss";

.search {

  width: 200px;
  @media (max-width: $mediaBreakpointLG) {
    width: 100%;
  }

  .searchInput {
    width: 100%;
    border: none;
    border-radius: 15px;
    background-color: $colorGrayLightBg;
    color: $colorBrand;
    padding: 4px 8px;
    @media (max-width: $mediaBreakpointLG) {
      border-radius: 2px;
    }
  }

  .searchInputIcon {
    display: inline-block;
    position: absolute;
    right:7px;
    top:3px;
    &:hover {
      cursor: pointer;
    }
  }

  &.active {
    .searchInput {
      //background-color: $colorWhite;
    }
  }

  /*width: 100%;
  height: 34px;

  transition: all 150ms cubic-bezier(0.42, 0, 0.58, 1);

  & > * {
    height: 100%;
  }

  input {
    width: calc(100% - 64px);
    height: 100%;
    color: $colorBrand;
    border: none;
    outline: none;
    font-size: 1em;
    font-weight: 700;
    padding: 0 30px 0 20px;

    &::placeholder {
      color: #989898;
    }
  }

  button {
    width: 64px;
    height: 100%;
    border: none;
    border-radius: 0;
    color: $colorBrand;
    background-color: $colorGold;
  }
}

.active {
  @media (min-width: 992px) {
    width: 150%;
  }*/
}
