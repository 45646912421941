@import "assets/App/scss/vars.scss";

.SwiperWrapper {
  position: relative;
}

.SwiperNavigation {
  border-radius: 50%;
  position: absolute;
  top:50%;
  transform: translateY(-50%) scale(0.7);
  &:hover {
    cursor: pointer;
  }
  &.navHidden {
    display: none;
  }
  @media (max-width: $mediaBreakpointXXL+60px) {
    display: none;
  }
}

.SwiperNavigationLeft {
  @extend .SwiperNavigation;
  left:-80px;
}

.SwiperNavigationRight {
  @extend .SwiperNavigation;
  right:-80px;
}

.NavIconBg {
  color: $colorGrayLightBg;
}

.NavIcon {
  color: $colorGrayDarkText;
}

.SwiperBulletBlue {
  :global(.swiper-pagination-bullet) {
    background-color: $colorWhite;
    opacity: 0.8;
    width: 12px;
    height: 12px;
  }
  :global(.swiper-pagination-bullet-active) {
    background-color: $colorBrand;
  }
}

.ArrowParentContainerNoItemsToShow {
  opacity: 0.3;
};
.ArrowSpanNoItemsToShow {
  cursor: not-allowed;
};

