.scrollToTop {
  width: 48px;
  height: 48px;

  border-radius: 50%;
  font-size: 0.875rem;

  right: 1.25rem;
  bottom: 4rem;
  z-index: 10;
}
